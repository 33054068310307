
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import ErrorBoundary from '@Components/ErrorBoundary';
import { AuthProvider } from '@Contexts/AuthContext/Auth.context';
import GlobalStateProvider from '@Contexts/GlobalStateProvider';
import { API_URL, PROJECT_ENV } from 'app.config';
import Axios from 'lib/Axios';
import { FacebookPixelScript, pageview } from 'lib/FacebookPixel';
import { Gpageview, GTagScript } from 'lib/GTag';
import ReactQueryConfig from 'lib/ReactQueryConfig';
import RTLCSS_PLUGIN from 'lib/RTLCSS';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Fragment, useEffect, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import GlobalStyle from 'stylesheets/GlobalStyle';
import { GridTheme } from 'stylesheets/Grid';
import theme from 'stylesheets/theme';
import SEO from '../next-seo.config';

Axios.defaults.baseURL = API_URL;
const routerChangeEventHandler = (url: any) => {
  pageview();
  Gpageview(url);
};
function MyApp({ Component: Page, pageProps, router: { locale } }: AppProps) {
  Axios.defaults.headers.common['Accept-Language'] = locale as string;
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient(ReactQueryConfig));

  useEffect(() => {
    if (PROJECT_ENV) {
      // This pageview only triggers the first time (it's important for Pixel to have real information)
      pageview();
      Gpageview(router as any);
      router.events.on('routeChangeComplete', routerChangeEventHandler);
      return () => {
        router.events.off('routeChangeComplete', routerChangeEventHandler);
      };
    }
  }, [router]);

  return (
    <ErrorBoundary>
      <div id="fb-customer-chat"></div>
      <FacebookPixelScript />
      {PROJECT_ENV && (
        <Fragment>
          <Script
            strategy="afterInteractive"
            id="FB_CHAT_ATTRS"
            dangerouslySetInnerHTML={{
              __html: `
                var chatbox = document.getElementById('fb-customer-chat');
                chatbox.setAttribute("page_id", "2116204985312883");
                chatbox.setAttribute("attribution", "setup_tool");
              `,
            }}
          />
          <Script
            strategy="afterInteractive"
            id="FB_CHAT_ATTRS"
            dangerouslySetInnerHTML={{
              __html: `
                window.fbAsyncInit = function() {
                  FB.init({
                    xfbml            : true,
                    version          : 'v10.0'
                  });
                };
          
                (function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) return;
                  js = d.createElement(s); js.id = id;
                  js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                  fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
              `,
            }}
          />
        </Fragment>
      )}
      <GTagScript />
      <AuthProvider ACL={pageProps.ACL}>
        <DefaultSeo {...SEO} />
        <StyleSheetManager stylisPlugins={locale === 'ar' ? [RTLCSS_PLUGIN] : []}>
          <ThemeProvider theme={theme}>
            <GridThemeProvider gridTheme={GridTheme}>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                  <GlobalStateProvider>
                    <Fragment>
                      <GlobalStyle />
                      <Page {...pageProps} />
                    </Fragment>
                  </GlobalStateProvider>
                </Hydrate>
              </QueryClientProvider>
            </GridThemeProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={locale === 'ar'}
      />
    </ErrorBoundary>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  